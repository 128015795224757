import React from "react";
import styles from '../styles/blogStyles.css'
import { Container, Row, Col } from "react-bootstrap";

const BlogDetails = () => {
  return (
    <>
      <Container>
        <Row>
          <Col><div className="bg-white"></div></Col>

          <Col  md='10'>
            <div className="text-align-center">
              <div className="mb-5 ">
                <img src="https://picsum.photos/500/500" alt="blogs" />
              </div>
              <div>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, doloribus temporibus. Soluta laborum rerum tempore veritatis quaerat hic aspernatur quas nostrum? Ad ipsum voluptatibus sequi quae, ullam beatae dolor ipsa?</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, doloribus temporibus. Soluta laborum rerum tempore veritatis quaerat hic aspernatur quas nostrum? Ad ipsum voluptatibus sequi quae, ullam beatae dolor ipsa?</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, doloribus temporibus. Soluta laborum rerum tempore veritatis quaerat hic aspernatur quas nostrum? Ad ipsum voluptatibus sequi quae, ullam beatae dolor ipsa?</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, doloribus temporibus. Soluta laborum rerum tempore veritatis quaerat hic aspernatur quas nostrum? Ad ipsum voluptatibus sequi quae, ullam beatae dolor ipsa?</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, doloribus temporibus. Soluta laborum rerum tempore veritatis quaerat hic aspernatur quas nostrum? Ad ipsum voluptatibus sequi quae, ullam beatae dolor ipsa?</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, doloribus temporibus. Soluta laborum rerum tempore veritatis quaerat hic aspernatur quas nostrum? Ad ipsum voluptatibus sequi quae, ullam beatae dolor ipsa?</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, doloribus temporibus. Soluta laborum rerum tempore veritatis quaerat hic aspernatur quas nostrum? Ad ipsum voluptatibus sequi quae, ullam beatae dolor ipsa?</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, doloribus temporibus. Soluta laborum rerum tempore veritatis quaerat hic aspernatur quas nostrum? Ad ipsum voluptatibus sequi quae, ullam beatae dolor ipsa?</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, doloribus temporibus. Soluta laborum rerum tempore veritatis quaerat hic aspernatur quas nostrum? Ad ipsum voluptatibus sequi quae, ullam beatae dolor ipsa?</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, doloribus temporibus. Soluta laborum rerum tempore veritatis quaerat hic aspernatur quas nostrum? Ad ipsum voluptatibus sequi quae, ullam beatae dolor ipsa?</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, doloribus temporibus. Soluta laborum rerum tempore veritatis quaerat hic aspernatur quas nostrum? Ad ipsum voluptatibus sequi quae, ullam beatae dolor ipsa?</p>
              </div>
            </div>
          </Col>

          <Col><div className="bg-white"></div></Col>
        </Row>
      </Container>


    </>
  );
};

export default BlogDetails;
