import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { blogsDummyData } from "../blog-data/blog-data";
import '../styles/blogStyles.css';
import { useNavigate } from "react-router-dom";

const BlogListing = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    "when an unknown printer took a galley of type and scrambled it to make a type specimen book"
  ])
  const [readMore, setReadMore] = useState(false);
  return (
    <>
      <Container className="alert alert-success w-100">
        <h1 className="page-title">Blogs</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Inventore quod at dolorum repellendus nihil dolorem, itaque adipisci, id odio neque maxime esse autem perspiciatis, possimus et doloremque vitae voluptatibus officia.{" "}

        </p>
      </Container>
      <Container className="my-4 w-100">
        <Row>
          {
            blogsDummyData.map((item, index) => (
              !(index % 2) ? (
                <>
                  <Col md={6} >
                    <Card onClick={() => { navigate('/blogs/details/3') }} style={{ cursor: "pointer" }}>
                      <Card.Img
                        variant="top"
                        src={item.image}
                        height={300}
                        width={200}
                      />
                    </Card>
                  </Col>
                  <Col md={6} className="mt-4">
                    {/* <p>{data.map((i) => (i))}</p> */}
                    <p
                      onClick={() => setReadMore(!readMore)}>
                      {
                        readMore ? <p></p>
                          : `${"Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit, reprehenderit vero ea debitis libero quod modi distinctio at consequatur labore, doloremque eius, dolore rerum quos rem adipisci cum. Corporis, adipisci!".substring(0, 80)}...`
                      }
                      <span className="text-secondary mx-2 cursor-pointer">{readMore ? navigate('/blogs/details/3') : "  read more"}</span>
                    </p>
                  </Col>
                </>
              ) : (
                <>
                  <Col md={6} className="mt-4">
                    {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit, reprehenderit vero ea debitis libero quod modi distinctio at consequatur labore, doloremque eius, dolore rerum quos rem adipisci cum. Corporis, adipisci!
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Similique ea dolorum, praesentium eos eligendi rerum minus molestiae, soluta voluptate voluptas facere culpa id et repellat tenetur ut blanditiis autem error?
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam officia dolorem, illum atque nostrum numquam impedit molestiae similique rerum porro assumenda eum dolore! Quos voluptates nisi beatae fugit laborum obcaecati?
                    </p> */}

                    <p
                      onClick={() => setReadMore(!readMore)}>
                      {
                        readMore ? <p></p>
                          : `${"Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit, reprehenderit vero ea debitis libero quod modi distinctio at consequatur labore, doloremque eius, dolore rerum quos rem adipisci cum. Corporis, adipisci!".substring(0, 80)}...`
                      }
                      <span className="text-secondary mx-2 cursor-pointer">{readMore ? navigate('/blogs/details/3') : "  read more"}</span>
                    </p>
                  </Col>
                  <Col md={6}>
                    <Card onClick={() => { navigate('/blogs/details/3') }} style={{ cursor: "pointer" }}>
                      <Card.Img
                        variant="top"
                        src={item.image}
                        height={300}
                        width={200}
                      />
                    </Card>
                  </Col>
                </>
              )
            ))
          }
        </Row>
      </Container>
    </>
  )
}

export default BlogListing

