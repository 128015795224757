import React from 'react';
import { Container, Card, Badge } from 'react-bootstrap';
import Masonry from 'react-masonry-css';
import { listData } from '../../tales-of-startups/demo-data/demo-list-data';
import '../../../common/custom-button/custom-btn.css'
// import '../../tos-categories/styles/category-style.css'
import useRedirect from "../../../common/custom-hooks/useRedirect";
import '../styles/styleItem.css';
import '../../../common/tof-custom-card/styles/tofCustomCardStyles.css';

const TalesOfStartups = () => {
    const redirect = useRedirect();
    return (
        <>
            <Container className="alert alert-warning w-100">
                <h1 className="page-title">Tales of Startups</h1>
                <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an unknown printer took a galley of type and
                    scrambled it to make a type specimen book.{" "}
                </p>
            </Container>
            <Container>
                <Masonry
                    breakpointCols={{
                        default: 4,
                        1100: 3,
                        700: 2,
                        750: 2,
                        500: 1,
                    }}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                >
                    {
                        listData.map((item) => {
                            return (
                                <div className='d-flex justify-contnet-center align-item-center'>
                                    <Card style={{ width: '20rem' }} className='card customCard-contanier cursor-pointer' onClick={() => redirect("tales-of-startups/category")}>
                                        <Card.Body>
                                            <div className='customCard-title category mt-0'>INDUSTRY</div>
                                            <div className='styleItem'>{item?.category} </div>
                                            <div className='fw-bold mb-4 styleItem'>{item?.startupCount}</div>
                                            <div className="view-btn d-flex justify-content-center align-items-center gap-2" >
                                                view
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    height="1em"
                                                    viewBox="0 0 448 512"
                                                    fill="#ffffff"
                                                >
                                                    <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                                                </svg>
                                            </div>
                                            {/* <Card.Link href="#">Card Link</Card.Link> */}
                                            {/* <Card.Link href="#">Another Link</Card.Link> */}
                                        </Card.Body>
                                    </Card>
                                </div>
                            )
                        })
                    }
                </Masonry>
            </Container>
        </>
    )
}

export default TalesOfStartups
