
export const blogsDummyData = [
    {
        image: "https://jpeg.org/images/jpeg-home.jpg",
        descripton: "This is the first description of the image"
    },
    {
        image: "https://drive.google.com/uc?export=view&id=1kcs1xpHXgRitFsoXA4mFOC3-PC8GFsXD",
        descripton: "This is the second description of the image"
    },
    {
        image: "https://drive.google.com/uc?export=view&id=1kAJi5hbKGibYmRmIjs64lCv3aqOlB_Ad",
        descripton: "This is the thirs description of the image"
    },
    {
        image: "https://drive.google.com/uc?export=view&id=1kCLyqH2tJE9NHNmGGos2x6VKfd6qZTKS",
        descripton: "This is the fourth description of the image"
    },
    {
        image: "https://drive.google.com/uc?export=view&id=1kLCiQ0K99pOb93kA0tN9C7gXtUorrb3X",
        descripton: "This is the fifth description of the image"
    }
]
